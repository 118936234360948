"use client";

import * as Form from "@radix-ui/react-form";
import { useNotificationStore } from "@store/notificationStore";
import { FormEvent, useState } from "react";
import LoadingSpinner from "@components/loadingSpinner";
import { usePathname, useRouter } from "next/navigation";
import { joinClassNames } from "@utils/helpers";
import { createBrowserClient } from "@supabase/ssr";
import { SUPABASE_ANON_KEY, SUPABASE_URL } from "@utils/constants";
import { analytics } from "@components/analytics";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export default function UpdatePasswordForm() {
  const router = useRouter();
  const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);
  const pathname = usePathname();
  const { setSuccess, setFailure } = useNotificationStore((state) => ({
    setSuccess: state.setSuccess,
    setFailure: state.setFailure,
  }));
  const [error, setError] = useState({
    password_same: false,
    too_short: false,
    other: false,
  });
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setLoading(true);
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const { password } = Object.fromEntries(formData);

    try {
      const { error, data } = await supabase.auth.updateUser({
        password: password as string,
      });
      if (error) {
        setFailure(error.message, true);
        throw new Error(error.message);
      }
      (document.getElementById("password") as HTMLInputElement).value = "";
      (document.getElementById("confirm_password") as HTMLInputElement).value =
        "";
      setSuccess("Succesfully updated password!");
      analytics.identify(
        data?.user?.app_metadata?.iticket_user_uid
          ? data.user.app_metadata.iticket_user_uid.toLowerCase()
          : data.user.email,
        {
          id: data?.user?.app_metadata?.iticket_user_uid?.toLowerCase(),
          email: data?.user?.email,
        }
      );
      router.replace("/");
    } catch (err) {
      console.error(err);
      if ((err as any).message.includes("should be different")) {
        setFailure(
          "Oops! Your new password must be different to your old password.",
          true
        );
        setError((prev) => ({ ...prev, password_same: true }));
      } else {
        setFailure("Oops! We couldn't update your password.", true);
        setError((prev) => ({ ...prev, other: true }));
      }
    }
    setLoading(false);
  }

  return (
    <div className="mt-12 pt-4 border-t border-gray-300">
      <Form.Root
        onSubmit={handleSubmit}
        className="relative flex flex-col gap-4 mb-3"
      >
        <h2 className="mt-4 text-3xl font-bold text-black">Change Password</h2>
        <span className="text-gray-600 italic">
          Password should be at least 6 characters.
        </span>
        <div
          className={joinClassNames(
            "flex flex-col md:grid",
            pathname.includes("reset-password")
              ? "gap-4"
              : "grid-cols-2 gap-x-8 gap-y-4"
          )}
        >
          <Form.Field name="password" className="flex flex-col gap-1">
            <div className="flex items-center gap-2 h-6">
              <Form.Label htmlFor="password" className="text-black font-medium">
                New Password
              </Form.Label>
              <Form.Message
                match="valueMissing"
                className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
              >
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">Required</span>
              </Form.Message>
              <Form.Message
                match="tooShort"
                className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
              >
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">Too short</span>
              </Form.Message>
            </div>
            <Form.Control
              id="password"
              name="password"
              aria-label="new-password"
              className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
              type="password"
              autoComplete="new-password"
              onChange={() => {
                setError({
                  password_same: false,
                  other: false,
                  too_short: false,
                });
              }}
              minLength={6}
              required
            />
          </Form.Field>
          <Form.Field name="confirm_password" className="flex flex-col gap-1">
            <div className="flex items-center gap-2 h-6">
              <Form.Label
                htmlFor="confirm_password"
                className="text-black font-medium"
              >
                Confirm Password
              </Form.Label>
              <Form.Message
                match="valueMissing"
                className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
              >
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">Required</span>
              </Form.Message>
              <Form.Message
                match={(value, formData) => {
                  const { password } = Object.fromEntries(formData);
                  return value !== password;
                }}
                className="flex items-center gap-0.5 px-2 py-0.5 rounded-full bg-red-100 text-red-800"
              >
                <ExclamationCircleIcon className="h-4 w-4 stroke-2" />
                <span className="text-sm">Must match</span>
              </Form.Message>
            </div>
            <Form.Control
              id="confirm_password"
              aria-label="confirm-password"
              className="rounded-md border border-gray-300 px-2 py-1.5 text-black focus:outline-none focus:border-2 focus:py-[5px] focus:px-[7px] focus:border-indigo-500"
              type="password"
              onChange={() => {
                setError({
                  password_same: false,
                  other: false,
                  too_short: false,
                });
              }}
              required
            />
          </Form.Field>
        </div>
        <Form.Submit
          className={joinClassNames(
            "mt-4 col-span-2 py-2 px-4 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 disabled:cursor-not-allowed text-white font-semibold transition-colors duration-200 ease-in-out",
            pathname.includes("reset-password") ? "w-full" : "w-fit"
          )}
          disabled={loading}
        >
          {loading ? (
            <div className="flex justify-center items-center gap-2">
              <LoadingSpinner size="18" color="#ffffff" />
              <span>Saving...</span>
            </div>
          ) : (
            <span>Save</span>
          )}
        </Form.Submit>
        {error.other ? (
          <div className="absolute top-full mt-2 flex items-start gap-1 px-2 py-0.5 rounded-md bg-red-100 text-red-800">
            <ExclamationCircleIcon className="h-4 w-4 stroke-2 shrink-0 mt-0.5" />
            <span className="text-sm">
              Oops! We couldn&apos;t update your password.
            </span>
          </div>
        ) : error.password_same ? (
          <div className="absolute top-full mt-2 flex items-start gap-1 px-2 py-0.5 rounded-md bg-red-100 text-red-800">
            <ExclamationCircleIcon className="h-4 w-4 stroke-2 shrink-0 mt-0.5" />
            <span className="text-sm">
              Oops! Your new password must be different to your old password.
            </span>
          </div>
        ) : (
          <></>
        )}
      </Form.Root>
    </div>
  );
}
